import './admin.scss';
import downArrow from 'assets/icons/down-arrow.svg';
import { CreateLot } from './createLot/createLot';
import { EditLot } from './editLot/editLot';
import { ManageLots } from './manageLots/manageLots';
import { Menu } from 'views/buttons/menu/Menu';
import { Menus, LotCreationSteps, LotEditionSteps } from 'constants/index';
import { useEffect } from 'react';
import { useAppSelector } from 'redux/hooks';
import { selectCurrentStep as selectCurrentStepCreation } from 'redux/slices/lotCreation';
import { selectCurrentStep as selectCurrentStepEdition } from 'redux/slices/lotEdition';
import {
  selectCurrentTab,
  selectDisplayTabMenu,
  selectMenus,
  setCurrentTab,
  setDisplayTabMenu,
  setMenus
} from 'redux/slices/adminMenu';
import { useDispatch } from 'react-redux';
import { Statistics } from './statistics/statistics';
import { ManageSectors } from './manageSectors/manageSectors';
import { ManageSteps } from './manageSteps/manageSteps';
import { ManageMaps } from './manageMaps/manageMaps';
import { emptySector, goTo } from 'redux/slices/manageSectors';
import { ManageStep } from 'constants/enums';

export function Admin() {
  const currentTab = useAppSelector(selectCurrentTab);
  const displayTabMenu = useAppSelector(selectDisplayTabMenu);
  const menus = useAppSelector(selectMenus);

  const dispatch = useDispatch();

  const coloredKeywords = [
    'Modifier',
    'Créer',
    'maps',
    'publications',
    'secteurs',
    '/',
    'métiers',
    'Statistiques',
    'étapes'
  ];

  useEffect(() => {
    dispatch(setDisplayTabMenu(false));

    const menuUpdated = updateMenu(currentTab);

    dispatch(setMenus(menuUpdated));
  }, [currentTab]);

  const openTabMenu = () => {
    dispatch(setDisplayTabMenu(true));
  };

  const updateMenu = (menu: string) => {
    // reference array used to keep the menus order
    const refMenus: string[] = [
      Menus.EDIT,
      Menus.CREATE,
      Menus.MAPS,
      Menus.SECTORS,
      Menus.STEPS,
      Menus.MANAGE,
      Menus.STATS
    ];

    // shallow copy of the original order
    const sortedMenus = [...refMenus];

    // get the index of selected menu
    const index = sortedMenus.indexOf(menu);

    // we delete the element at the aray
    sortedMenus.splice(index, 1);

    // put the menu in the first position
    sortedMenus.splice(0, 0, menu);

    return sortedMenus;
  };

  const colorLabel = (menuLabel: string) => {
    let result = null;
    const menuLabelArray = menuLabel.split(' ');

    result = (
      <span
        onClick={() => {
          dispatch(setCurrentTab(menuLabel));
          if (menuLabel === 'Gestion des étapes' || menuLabel === 'Gestion des secteurs / métiers') {
            dispatch(emptySector());
            dispatch(goTo(ManageStep.sector));
          }
        }}
      >
        {menuLabelArray.map((word: string, index: number) => (
          <span key={index} className={coloredKeywords.includes(word) ? 'title-container__title-pink' : ''}>
            {word}{' '}
          </span>
        ))}
      </span>
    );

    return result;
  };

  // check the current step of lot creation to edit the admin display
  let stepCreation: LotCreationSteps = useAppSelector(selectCurrentStepCreation);
  let stepEdition: LotEditionSteps = useAppSelector(selectCurrentStepEdition);

  return (
    <>
      <div
        className={
          (stepCreation === LotCreationSteps.zones || stepEdition === LotEditionSteps.zones) &&
          currentTab !== Menus.MANAGE
            ? 'admin-map-container'
            : currentTab === Menus.MANAGE
            ? 'manage-lot-container'
            : `admin-container ${currentTab === Menus.STEPS ? 'admin-steps' : ''}`
        }
      >
        <div
          className={
            stepCreation === LotCreationSteps.zones && currentTab !== Menus.MANAGE
              ? 'title-container menu-to-right-creation'
              : stepEdition === LotEditionSteps.zones && currentTab !== Menus.MANAGE
              ? 'title-container menu-to-right-edition'
              : 'title-container'
          }
        >
          <div onClick={openTabMenu} className="title-container__title">
            {colorLabel(currentTab)}
            <img src={downArrow} alt="down arrow" />
          </div>
          {displayTabMenu ? <Menu currentTab={currentTab} menus={menus} /> : null}
        </div>
        {currentTab === Menus.CREATE && <CreateLot />}
        {currentTab === Menus.EDIT && <EditLot />}
        {currentTab === Menus.MAPS && <ManageMaps />}
        {currentTab === Menus.MANAGE && <ManageLots />}
        {currentTab === Menus.SECTORS && <ManageSectors />}
        {currentTab === Menus.STEPS && <ManageSteps />}
        {currentTab === Menus.STATS && <Statistics />}
      </div>
    </>
  );
}
