// Manipulate file encoding
const fileEncoding = {
  async toBase64(file: File): Promise<string> {
    let base64Url: string = '';

    base64Url = await this.fileReader(file);

    return base64Url;
  },

  async toFile(dataUrl: string, fileName: string): Promise<File> {
    const res: Response = await fetch(dataUrl);
    const blob: Blob = await res.blob();
    const fileType: string | undefined = dataUrl.match(/^data:(.+);base64/)?.[1];
    return new File([blob], fileName, { type: fileType });
  },

  isBase64Encoded(dataUrl: string): boolean {
    if (dataUrl.length) {
      const result = dataUrl.includes('base64');
      return result;
    } else {
      return false;
    }
  },

  async toDataUrl(file: File): Promise<string> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result as string);
      reader.onerror = reject;
      reader.readAsDataURL(file);
    });
  },

  fileReader(file: File): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      let reader = new FileReader();

      reader.onloadend = () => {
        if (typeof reader.result === 'string') {
          const base64 = reader.result;
          resolve(base64);
        }
      };

      reader.onerror = () => {
        reject('error occured while converting file');
      };

      reader.readAsDataURL(file);
    });
  }
};

export default fileEncoding;
