import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'redux/store';
import { NotificationState } from 'constants/index';

const initialState: NotificationState = {
  type: '',
  message: '',
  gameZone: '',
  gameZoneId: ''
};

export const notificationSlice = createSlice({
  name: 'notification',
  initialState,
  reducers: {
    setNotificationType: (state, action: PayloadAction<string>) => {
      state.type = action.payload;
    },
    setNotificationMessage: (state, action: PayloadAction<string>) => {
      state.message = action.payload;
    },
    setNotificationGameZone: (state, action: PayloadAction<string>) => {
      state.gameZone = action.payload;
    },
    setNotificationGameZoneId: (state, action: PayloadAction<string>) => {
      state.gameZoneId = action.payload;
    }
  }
});

export const { setNotificationType, setNotificationMessage, setNotificationGameZone, setNotificationGameZoneId } =
  notificationSlice.actions;
export const selectNotificationType = (state: RootState) => state.notification.type;
export const selectNotificationMessage = (state: RootState) => state.notification.message;
export const selectNotificationGame = (state: RootState) => state.notification.gameZone;
export const selectNotificationGameZoneId = (state: RootState) => state.notification.gameZoneId;

export default notificationSlice.reducer;
