import { t } from 'i18next';
import { useEffect, useRef, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { emptySector, selectSector, selectSectors, setSector, setSectors } from 'redux/slices/manageSectors';
import { setNotificationMessage, setNotificationType } from 'redux/slices/notification';
import {
  editSector,
  getAllSectors,
  createSector as createSectorAPI,
  checkSectorLabel
} from 'services/sectors/sectorService';
import { Button } from 'views/buttons/base';
import { CommonTextInput } from 'views/components/base/inputs/TextInput';
import { EditSector } from './editSector/editSector';
import './manageSectors.scss';
import { setPopupData, setType } from 'redux/slices/popup';
import { Sector } from 'constants/types';
import { ActionMeta } from 'react-select';
import { DropDownInput } from 'views/components/base/inputs/DropDownInput';
import customDropDownStyles from '../editLot/editLotForm/customDropDownStyles';

export function ManageSectors() {
  const [label, setLabel] = useState<string>('');
  const [validity, setValidity] = useState<boolean>(false);
  const [validityEdit, setValidityEdit] = useState<boolean>(false);
  const [textWasChanged, setTextWasChanged] = useState<boolean>(false);
  const dispatch = useAppDispatch();
  const [randomKey, setRandomKey] = useState<number>(Math.random());
  const [randomKeyEdit, setrandomKeyEdit] = useState<number>(Math.random());
  const [randomKeyDropDown, setRandomKeyDropDown] = useState<number>(Math.random());
  const sector = useAppSelector(selectSector);
  const [currentSector, setCurrentSector] = useState<{ id?: string; label?: string }>();
  const sectors = useAppSelector(selectSectors);
  function onChange(text: string) {
    setLabel(text);
  }

  function isValidEdit(value: boolean) {
    setValidityEdit(value);
  }

  const getSectors = async () => {
    const sectors = await getAllSectors();
    dispatch(setSectors(sectors));
  };

  function updateLabel(value: string) {
    setCurrentSector({ ...currentSector, label: value });
  }
  function updateCurrentId(value: string) {
    setCurrentSector({ id: value });
  }
  async function updateSector() {
    await editSector({ id: currentSector?.id!, label: currentSector?.label! })
      .then(() => {
        dispatch(setNotificationType('validation'));
        dispatch(setNotificationMessage('Le secteur / métier a été mis à jour'));
        const s = Object.assign({}, sector);
        s.label = currentSector?.label!;
        dispatch(setSector(s));
        setCurrentSector({ ...currentSector, label: undefined });
        getSectors();
      })
      .catch((e) => {
        console.error(e);
        dispatch(setNotificationType('error'));
        dispatch(setNotificationMessage('Une erreur est survenue'));
      });
  }

  async function createSector() {
    const response = await checkSectorLabel(label);
    if (!response.exist) {
      await createSectorAPI({ label: label, value: 'null' })
        .then((sector) => {
          setLabel('');
          dispatch(setPopupData({ sector }));
          dispatch(setType('createdSector'));
          getSectors();
          setRandomKey(Math.random());
        })
        .catch((e) => {
          console.error(e);
          dispatch(setNotificationType('error'));
          dispatch(setNotificationMessage('Une erreur est survenue'));
        });
    }
  }

  useEffect(() => {
    dispatch(emptySector());
    return () => {};
  }, []);

  function onclick() {
    dispatch(emptySector());
    setCurrentSector({ id: undefined, label: undefined });
    setRandomKeyDropDown(Math.random());
  }
  async function handleSelectChange(sector: unknown, actionMeta: ActionMeta<unknown>) {
    dispatch(setSector(sector as Sector));
    updateCurrentId((sector as Sector)._id!);
    setRandomKey(Math.random());
    setLabel('');
  }

  useEffect(() => {
    // Retrieve sectors
    getSectors();

    return () => {};
  }, []);
  return (
    <>
      <div className="sectors__container">
        <div className="sectors__edit">
          <DropDownInput
            key={randomKeyDropDown + sectors.length}
            options={sectors}
            placeholder={t('Sélectionner votre secteur / métier')}
            styles={customDropDownStyles}
            onChange={handleSelectChange}
            value={sector}
            defaultValue={null}
          />
        </div>
        <div className="sectors__or">ou</div>
        <div className="sectors__new" key={randomKey}>
          <CommonTextInput
            onClick={onclick}
            isEdit={false}
            onChange={onChange}
            inputPlaceholder={'Créer un secteur / métier (30 caracteres max.)'}
            labelPlaceholder={''}
            labelClasses="lot-new_form__input-label"
            errorMessage={t('Le titre du secteur / métier doit faire entre 1 et 30 caracteres')}
            validationRules={{ isRequired: true, maxLength: 30, minLength: 0 }}
            setValidity={setValidity}
            textWasChanged={setTextWasChanged}
            existingValue={label}
            type="sector"
          />
        </div>
      </div>
      {sector && (
        <EditSector
          key={randomKeyEdit}
          onLabelChange={updateLabel}
          onSectorSelected={updateCurrentId}
          isValid={isValidEdit}
        />
      )}
      <div className="text-right btn-sectors">
        {currentSector?.id && (
          <Button
            label="Valider"
            type="submit"
            disabled={!currentSector.label || currentSector.label === '' || !validityEdit}
            classType={'primary-button-lg'}
            translation=""
            onClick={updateSector}
          />
        )}
        {currentSector?.id === undefined && (
          <Button
            label="Valider"
            type="submit"
            disabled={!validity || !textWasChanged}
            classType={'primary-button-lg'}
            translation=""
            onClick={createSector}
          />
        )}
      </div>
    </>
  );
}
