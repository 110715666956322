import type { PayloadAction } from '@reduxjs/toolkit';
import { CheckedModule, PopupState } from 'constants/index';
import { RootState } from 'redux/store';
import { createSlice } from '@reduxjs/toolkit';

const initialState: PopupState = {
  type: '',
  addCardModuleType: undefined,
  editCardModuleType: undefined,
  data: undefined,
  oldData: undefined
};

export const popupSlice = createSlice({
  name: 'popup',
  initialState,
  reducers: {
    setType: (state, action: PayloadAction<string>) => {
      state.type = action.payload;
    },
    setPopupData: (state, action: PayloadAction<any>) => {
      state.data = action.payload;
    },
    setAddCardModuleType: (state, action: PayloadAction<CheckedModule>) => {
      state.addCardModuleType = action.payload;
    },
    setEditCardModuleType: (state, action: PayloadAction<CheckedModule>) => {
      state.editCardModuleType = action.payload;
    },
    setCurrentDataForRollback: (state) => {
      state.oldData = state.data;
    }
  }
});

export const { setType, setPopupData, setAddCardModuleType, setEditCardModuleType, setCurrentDataForRollback } =
  popupSlice.actions;
export const selectPopupType = (state: RootState) => state.popup.type;
export const selectAddCardModuleType = (state: RootState) => state.popup.addCardModuleType;
export const selectPopupData = (state: RootState) => state.popup.data;
export const selectPopupDataForRollback = (state: RootState) => state.popup.oldData;

export default popupSlice.reducer;
